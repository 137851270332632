import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private httpClient: HttpClient) {}

  public signedUrl(fileName: string): Observable<{ signed: string; url: string }> {
    return this.httpClient.post<{ signed: string; url: string }>(`files/signed-url`, { fileName });
  }

  public sendFile(file: File): Observable<string> {
    return this.signedUrl(file.name).pipe(
      filter(res => !!res?.signed),
      switchMap(
        res =>
          new Observable<{
            signed: string;
            url: string;
            blob: Blob;
          }>(subscriber => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = err => {
              subscriber.error(err);
              subscriber.complete();
            };
            reader.onload = () => {
              subscriber.next({
                ...res,
                blob: this.b64toBlob(reader.result as string, file.type),
              });
              subscriber.complete();
            };
          }),
      ),
      switchMap((res: { signed: string; url: string; blob: Blob }) =>
        this.httpClient.put(res.signed, res.blob).pipe(map(() => res.url)),
      ),
    );
  }

  public sendImage(name: string, b64Data: string) {
    const fileType = `image/${b64Data.split(';')[0].split('/')[1]}`;
    return this.signedUrl(name).pipe(
      filter(res => !!res?.signed),
      map(res => {
        return {
          ...res,
          blob: this.b64toBlob(b64Data, fileType),
        };
      }),
      switchMap((res: { signed: string; url: string; blob: Blob }) =>
        this.httpClient.put(res.signed, res.blob).pipe(map(() => res.url)),
      ),
    );
  }

  private b64toBlob(b64Data: string, type = 'image/png'): Blob {
    console.log(type);
    let data;
    const aux = b64Data.split(',');
    if (aux.length > 1) {
      data = aux[1];
    } else {
      data = b64Data;
    }
    const byteString = atob(data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
