import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { showErrorAnimation } from 'angular-v2-animations';
import { ApiValidatorService } from 'angular-v2-services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-error-message',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, AngularSvgIconModule],
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  animations: [showErrorAnimation],
})
export class ErrorMessageComponent implements OnChanges, OnInit, OnDestroy {
  @Input() control: AbstractControl | any | null = null;
  @Input() apiError?: { message: string } | { message: string }[];
  @Input() message?: string;
  @Input() prefix: 'GA' | 'SH' = 'SH';
  @Input() html = false;
  @Input() icon = false;
  private controlSubscribe?: Subscription;

  public get showError() {
    return this.control?.dirty || this.control.touched;
  }

  @HostBinding('class.has-error')
  public get errorMessage(): string | null {
    if (this.message) {
      return this.message;
    }
    if (this.control) {
      for (const key in this.control.errors) {
        if (this.control.errors[key] && this.showError && key !== 'apiError') {
          return ApiValidatorService.getValidationMessage(key, this.control.errors[key], this.prefix) || key;
        }
      }
    }
    if (Array.isArray(this.apiError)) {
      return this.apiError.join(`\n`);
    }
    if (this.apiError) {
      return this.apiError?.message ?? this.apiError;
    }
    return null;
  }

  public ngOnDestroy(): void {
    if (this.controlSubscribe) {
      this.controlSubscribe.unsubscribe();
    }
  }

  public ngOnInit(): void {
    if (this.control) {
      this.controlSubscribe = this.control.valueChanges.subscribe(() => {
        if (this.apiError) {
          delete this.apiError;
        }
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['apiError'] && changes['apiError'].currentValue && this.control) {
      this.control.setErrors({
        apiError: true,
      });
      this.control.markAsDirty();
    }
  }
}
