import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IVaWidgetCategory } from '@smarttypes/core';
import { CalculatedCompanyMetrics, IMetricValue } from '@smarttypes/metrics';
import { TipComponent } from '@ui/common';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { first, get, isString } from 'lodash';

import { WidgetsInteractionItemComponent } from '../widgets-interaction-item';

interface PeriodParsed {
  name?: string;
  displayName?: string;
  sum: number;
  change?: number;
  isPeriod?: boolean;
}

interface IWidgetInteraction {
  displayName: string;
  name: string;
  sum: number;
  change: number;
  exists: boolean;
}

@Component({
  selector: 'ui-widgets-interaction',
  templateUrl: './widgets-interaction.component.html',
  styleUrls: ['./widgets-interaction.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    ButtonRectangleComponent,
    WidgetsInteractionItemComponent,
    TipComponent,
    ButtonCircleComponent,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetsInteractionComponent implements OnInit {
  public percentChange = 0;
  @Input() public downloading = false;
  @Output() public export = new EventEmitter<void>();
  @Input() public widgets?: IVaWidgetCategory[] | any;
  @Input() public isApartment = false;
  public items?: PeriodParsed[];
  public isAllItemsLoaded = false;
  public currentPeriodParsed: PeriodParsed[] = [];
  public previousPeriodParsed: PeriodParsed[] = [];

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService) {}

  private _currentPeriod?: CalculatedCompanyMetrics;

  get currentPeriod(): CalculatedCompanyMetrics | undefined | any {
    return this._currentPeriod || undefined;
  }

  @Input() set currentPeriod(value: CalculatedCompanyMetrics | undefined) {
    this._currentPeriod = value;

    this.prepareData();
    this.calculateChange();
    this.cdr.detectChanges();
  }

  private _previousPeriod?: CalculatedCompanyMetrics;

  get previousPeriod(): CalculatedCompanyMetrics | undefined | any {
    return this._previousPeriod || undefined;
  }

  @Input() set previousPeriod(value: CalculatedCompanyMetrics | undefined) {
    this._previousPeriod = value;
  }

  get isPercentChangePositive(): boolean {
    return this.percentChange > 0;
  }

  get isPercentChangeNegative(): boolean {
    return this.percentChange < 0;
  }

  get showLoadButton(): number {
    const count = this.currentPeriodParsed?.length || 0;

    if (count > 5) {
      return count - 5;
    }

    return 0;
  }

  get allItems(): number {
    return this.currentPeriodParsed?.length || 0;
  }

  get iconPath(): string {
    return this.isPercentChangePositive
      ? 'assets/icons/icons-arrow-up-alt.svg'
      : 'assets/icons/icons-arrow-down-alt.svg';
  }

  get allClicks(): number {
    return this.currentPeriodParsed?.reduce((sum, obj) => sum + obj.sum, 0) || 0;
  }

  get isPreviousPeriod(): boolean {
    return this.previousPeriodParsed?.length > 0;
  }

  ngOnInit(): void {
    this.prepareData();
    this.calculateChange();
    this.cdr.detectChanges();
  }

  public onLoadClick(): void {
    this.items = this.isAllItemsLoaded ? this.currentPeriodParsed?.slice(0, 5) : this.currentPeriodParsed;
    this.isAllItemsLoaded = !this.isAllItemsLoaded;
    this.cdr.detectChanges();
  }

  public getWidgetById(id: string | undefined): any {
    if (!id || this.isApartment) {
      return;
    }
    const normalizedName = this.normalizeName(id);
    const item = this.findWidgetByName(normalizedName);

    if (!item) {
      return this.findWidgetInCategory(normalizedName);
    }

    return item;
  }

  private mergeWidgetsByExistsFlag(widgets: IWidgetInteraction[]): IWidgetInteraction[] {
    const mergedItems: { [displayName: string]: IWidgetInteraction } = {};
    const existingWidgets: IWidgetInteraction[] = [];

    widgets.forEach(widget => {
      if (widget.exists) {
        existingWidgets.push(widget);
      } else {
        const existingItem = mergedItems[widget.displayName];

        if (existingItem) {
          existingItem.sum += widget.sum;
        } else {
          mergedItems[widget.displayName] = { ...widget };
        }
      }
    });

    return [...Object.values(mergedItems), ...existingWidgets].sort((a, b) => b.sum - a.sum);
  }

  private processPeriod(period: CalculatedCompanyMetrics | undefined): IWidgetInteraction[] {
    if (!period) return [];

    const filteredPeriod = this.filterWidgetMetrics(period);
    const widgets = Object.entries(filteredPeriod).map(([key, value]) => this.createWidgetItem(key, value));
    return this.mergeWidgetsByExistsFlag(widgets);
  }

  private filterWidgetMetrics(period: CalculatedCompanyMetrics): Partial<CalculatedCompanyMetrics> {
    return Object.fromEntries(
      Object.entries(period).filter(([key]) => key.startsWith(this.isApartment ? 'GA_APARTMENT_' : 'GA_WIDGET_')),
    );
  }

  private createWidgetItem(key: string, value: IMetricValue[]): IWidgetInteraction {
    const widgetId = key.split(this.isApartment ? 'GA_APARTMENT_' : 'GA_WIDGET_')[1];
    const widget = this.getWidgetById(widgetId);

    return {
      displayName: this.getWidgetTitle(key, widgetId, widget),
      name: widgetId,
      sum: this.calculateSum(value),
      change: 0,
      exists: !!widget,
    } as IWidgetInteraction;
  }

  private getWidgetTitle(key: string, widgetId: string, widget: any): string {
    const title = get(widget, 'title', null);
    if (this.isApartment) {
      return `SH.ANALYTICS.${key}`;
    }

    if (key.startsWith('GA_WIDGET_OPEN_')) {
      return this.translateService.instant(key.replace('GA_WIDGET_OPEN_', 'SH.'));
    }

    if (!widget) {
      return this.translateService.instant(`SH.ANALYTICS.WIDGET_REMOVED`);
    }

    if (isString(title)) {
      return this.translateService.instant(`SH.FORM_WIDGET.${widgetId}.TITLE`);
    }

    if (!title) {
      return this.translateService.instant(`SH.FORM_WIDGET.${widgetId}.TITLE`);
    }

    const titles = Object.keys(title ?? {})
      .map(key => {
        if (get(title, key)) {
          return title[key];
        }
      })
      .filter(k => !!k) as string[];

    return first(titles) ?? '';
  }

  private calculateSum(value: any): number {
    return value.reduce((n: number, { sum }: { sum: number }) => n + sum, 0);
  }

  private prepareData(): void {
    this.currentPeriodParsed = this.processPeriod(this.currentPeriod);
    this.previousPeriodParsed = this.processPeriod(this.previousPeriod);

    this.percentChange =
      this.previousPeriodParsed?.length > 0
        ? this.compareCurrentAndPrevious(this.previousPeriodParsed || [], this.currentPeriodParsed || [])
        : 0;

    this.items = this.currentPeriodParsed?.slice(0, 5);
  }

  private compareCurrentAndPrevious(arr1: PeriodParsed[], arr2: PeriodParsed[]): number {
    const sum1 = arr1.reduce((sum, obj) => sum + obj.sum, 0);
    const sum2 = arr2.reduce((sum, obj) => sum + obj.sum, 0);

    return Math.floor(((sum2 - sum1) / sum1) * 100);
  }

  private calculateChange(): void {
    this.currentPeriodParsed?.forEach(item => {
      item.change = this.compareSumByName(item);
      item.isPeriod = this.previousPeriodParsed?.some(obj => obj.name === item.name);
    });
  }

  private compareSumByName(obj: PeriodParsed): number {
    const objWithSameName = this.previousPeriodParsed?.find(item => item.name === obj.name);
    const percentChange = objWithSameName ? ((obj.sum - objWithSameName.sum) / objWithSameName.sum) * 100 : 0;

    return !objWithSameName ? 0 : Math.floor(percentChange);
  }

  private extractCategoryName(name?: string): string {
    const categoryName = name?.split('-')[0];

    if (categoryName === 'custom') {
      return 'custom-widget-v1';
    }

    return categoryName || '';
  }

  private normalizeName(name: string | undefined): string {
    return name?.toLowerCase().replace('_', '-') || '';
  }

  private findWidgetByName(name: string): any | undefined {
    return this.widgets?.find((item: any) => item.code?.toLowerCase() === name);
  }

  private findWidgetInCategory(normalizedName: string): any {
    const categoryName = this.extractCategoryName(normalizedName);
    const categoryWidget = this.widgets?.find((item: any) => item.code === categoryName);

    const allWidgets = [
      ...((categoryWidget as any)?.data?.widgets || []),
      ...((categoryWidget as any)?.data?.objects || []),
    ];

    if (this.isMealWidget(normalizedName)) {
      return this.findMealWidget(allWidgets, normalizedName);
    }

    return allWidgets.find((item: any) => item.id === normalizedName);
  }

  private isMealWidget(normalizedName: string): boolean {
    return ['meals-dinner', 'meals-lunch', 'meals-breakfast', 'meals-high_tea'].includes(normalizedName);
  }

  private findMealWidget(allWidgets: PeriodParsed[], normalizedName: string): PeriodParsed | undefined {
    const mealName = normalizedName.split('-')[1].replace('_', '-');
    return allWidgets.find((item: any) => item.name === mealName);
  }
}
