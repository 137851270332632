import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { LoggedInGuard } from './core/guards/logged-in-guard';
import { NotFoundComponent } from './views/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'wait-loading',
    redirectTo: 'auth/wait-loading',
  },
];

if (environment.loginPageEnabled) {
  routes.push({
    path: 'login',
    redirectTo: 'auth/login',
  });
}

if (environment.registrationEnabled) {
  routes.push({
    path: 'register',
    redirectTo: 'auth/register',
  });
}

routes.push(
  ...[
    {
      path: '404',
      component: NotFoundComponent,
      title: '404',
    },
    { path: '**', redirectTo: '/404' },
  ],
);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
