import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit {
  @Input() public value?: boolean;
  @Input() public disabled = false;
  private isRegister = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (_: any) => {};

  public ngOnInit() {
    this.emitChange();
  }

  public valueChange(state: boolean) {
    if (!this.disabled && this.isRegister) {
      this.value = state;
      this.onChange(this.value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched() {}

  public emitChange() {
    this.onChange(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
    this.isRegister = true;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: boolean): void {
    if (!this.disabled && this.isRegister) {
      this.value = obj;
    }
  }
}
