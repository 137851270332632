import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GrabberComponent } from '@ui/common';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, SwitchComponent } from '@ui/common/forms';
import { ConfirmModalComponent } from '@ui/common/modals';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { toggleContentAnimation } from 'angular-v2-animations';
import { getIcon, isEmoji } from 'angular-v2-utils';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'wg-form-widget',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonCircleComponent,
    SwitchComponent,
    GrabberComponent,
    FormsModule,
    ErrorMessageComponent,
    ModalModule,
    AngularSvgIconModule,
    NgxTippyModule,
  ],
  templateUrl: './form-widget.component.html',
  styleUrls: ['./form-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleContentAnimation],
})
export class FormWidgetComponent {
  @Input() icon?: string;
  @Input() header?: string;
  @Input() enabled = false;
  @Input() expanded = false;
  @Input() isDraggable = false;
  @Input() isRemovable = false;
  @HostBinding('class.clickable') @Input() isExpandable = true;
  @Input() permalink = false;
  @Input() positionIndex?: number;
  @Input() error = false;
  @Input() subCompany = false;
  @Output() public stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public remove: EventEmitter<number> = new EventEmitter<number>();
  public permalinkTippyConfig: NgxTippyProps = {
    arrow: true,
    theme: 'dropdown',
    maxWidth: '508px',
    placement: 'bottom-start',
    offset: [0, 5],
    allowHTML: true,
    interactive: true,
    interactiveBorder: 50,
    trigger: 'click',
    animation: 'scale',
  };

  constructor(private modalService: BsModalService) {}

  get widgetIcon(): string {
    return getIcon(this.icon);
  }

  public get isEmoji(): boolean {
    return isEmoji(this.icon);
  }

  public preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  public onToggleContent(): void {
    if (this.isExpandable) {
      this.expanded = !this.expanded;
    }
  }

  public onRemove(): void {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        text: {
          header: 'SH.VISITORS_PLACE.REMOVE_MODAL.HEADER',
          body: `SH.VISITORS_PLACE.REMOVE_MODAL.TEXT`,
          submit: 'SH.VISITORS_PLACE.REMOVE_MODAL.SUBMIT',
          btnClass: 'danger',
        },
        confirmFunc: () => this.remove.emit(),
      },
      class: 'modal-dialog-small',
    });
  }

  public onToggleActive(): void {
    this.stateChange.emit(this.enabled);
  }
}
