import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { QuillEditorComponent, QuillViewHTMLComponent } from 'ngx-quill';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { ErrorMessageComponent } from '../error-messages/error-message.component';

@Component({
  selector: 'ui-whatsapp-editor',
  templateUrl: './whatsapp-editor.component.html',
  styleUrls: ['./whatsapp-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WhatsappEditorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgxTippyModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    QuillEditorComponent,
    PickerModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    QuillViewHTMLComponent,
    NgSelectModule,
  ],
})
export class WhatsappEditorComponent implements ControlValueAccessor {
  @Input() templateTop: string | undefined;
  @Input() templateBottom: string | undefined;
  @Input() disabled = false;
  @Input() maxLines = 3;
  @Input() placeholder = '';
  @Input() maxLength = 800;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;
  @ViewChild(QuillEditorComponent, { static: false })
  editor?: QuillEditorComponent;
  @Output() countChange = new EventEmitter<number>();
  editorFormats = ['bold', 'image', 'italic', 'link', 'strike', 'underline'];
  value = '';
  content = '';
  showEmoji = false;
  messageCounter = 0;
  private onModelChange!: (value: string) => void;
  private onTouch!: () => void;

  public registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(msg?: string): void {
    this.value = this.convertTextToHtml(msg ?? '');
    this.content = this.value;
    this.countCharacters(msg);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
    this.disabled = isDisabled;
  }

  changeValue(msg: string) {
    this.value = this.convertHtmlToText(msg ?? '');
    this.countCharacters(msg);
    this.onModelChange(this.value);
  }

  onSelectEmoji(event: { emoji: EmojiData; $event: PointerEvent }) {
    const value = event.emoji.native ?? '';
    const range = this.editor?.quillEditor?.getSelection(true);
    this.editor?.quillEditor?.insertEmbed(range?.index ?? 0, 'text', value);
    this.showEmoji = false;
    this.writeValue(this.editor?.quillEditor?.root?.innerHTML);
    setTimeout(() => {
      this.editor?.quillEditor?.setSelection(
        (range?.index ?? 0) + (value?.length ?? 0),
        0,
      );
    }, 5);
  }

  onToggleEmoji() {
    this.showEmoji = !this.showEmoji;
  }

  contentChanged() {
    const contentLength = this.editor?.quillEditor?.getLength() ?? 0;
    if (contentLength > this.maxLength) {
      this.editor?.quillEditor.history.undo();
    }
  }

  private convertHtmlToText(str: string | null): string {
    let value = (str ?? '')
      ?.replace(/<strong>/g, '*')
      ?.replace(/<\/strong>/g, '*')
      ?.replace(/<\/p>/g, '\n')
      ?.replace(/<[^>]*>/g, '');

    if (value.endsWith('\n')) {
      value = value.slice(0, -1);
    }

    return this.parseWhatsAppBody(value);
  }

  private convertTextToHtml(str: string | null): string {
    return (str ?? '')
      ?.split('\n')
      ?.map(s => `<p>${s}</p>`)
      ?.join('')
      ?.trim()
      ?.replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>');
  }

  private countCharacters(msg?: string): void {
    this.messageCounter = this.convertHtmlToText(msg ?? '')?.length ?? 0;
  }

  private parseWhatsAppBody(value: string) {
    const bodyParts = value
      .trim()
      .split(`\n`)
      .filter(s => s.trim() !== '');
    const finalBodyParts =
      bodyParts.length >= 3
        ? bodyParts.slice(0, 2)
        : [bodyParts[0] || ' ', bodyParts[1] || ' '];
    finalBodyParts.push(
      bodyParts
        .slice(2)
        .filter(a => a && a.trim() !== '')
        .join(' ') || ' ',
    );

    return finalBodyParts.join(`\n`).trim();
  }
}
