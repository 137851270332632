import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-payment-no-provider',
  templateUrl: './payment-no-provider.component.html',
  styleUrls: ['./payment-no-provider.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, ButtonRectangleComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentNoProviderComponent {
  @Input() showButton = true;
  @HostBinding('class.small') @Input() small = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
