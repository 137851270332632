import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NlToBrPipe } from 'angular-v2-pipes';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BaseModalComponent } from '../base-modal/base-modal.component';

export interface ConfirmModelText {
  header?: any;
  body?: any;
  submit?: any;
  submit2?: any;
  cancel?: any;
  image?: any;
  btnClass?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  btnClass2?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  btnCancelClass?: 'secondary' | 'tertiary';
  hint?: string;
  blockButtons?: boolean;
  icon?: string;
  large?: boolean;
}

export interface ConfirmModelWarning {
  icon: string;
  text: string;
}

@Component({
  selector: 'ui-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonRectangleComponent,
    BaseModalComponent,
    NlToBrPipe,
    AngularSvgIconModule,
  ],
})
export class ConfirmModalComponent implements OnInit {
  @HostBinding('class.modal-info') modalInfo = true;
  @Input() text?: ConfirmModelText;
  @Input() warning?: ConfirmModelWarning;
  @Input() canClose = true;
  @Input() coverImage?: string;
  @Input() confirmFunc?: () => void;
  @Input() confirmFunc2nd?: () => void;
  @Input() cancelFunc?: () => void;
  public cfg?: ConfirmModelText;
  private cfgDefault: ConfirmModelText = {
    header: 'SH.MODAL_CONFIRM.HEADER',
    body: 'SH.MODAL_CONFIRM.TEXT',
    submit: '',
    submit2: '',
    cancel: 'SH.CANCEL',
    image: '',
    hint: '',
    btnClass: 'primary',
    btnClass2: 'tertiary',
    btnCancelClass: 'tertiary',
  };

  constructor(public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.cfg = Object.assign({}, this.cfgDefault, this.text);
  }

  public onConfirmClick(): void {
    if (this.confirmFunc) {
      this.confirmFunc();
      this.bsModalRef.hide();
    }
  }

  public onConfirm2Click(): void {
    if (this.confirmFunc2nd) {
      this.confirmFunc2nd();
      this.bsModalRef.hide();
    }
  }

  public onCancelClick(): void {
    if (this.cancelFunc) {
      this.cancelFunc();
      this.bsModalRef.hide();
    } else {
      this.bsModalRef.hide();
    }
  }
}
