import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { FormComponent } from '@ui/common/forms';
import { InlineToastComponent, ToastTypes } from '@ui/features';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { copyToClipboard } from 'angular-v2-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wg-form-widget-permalink',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonCircleComponent,
    FormsModule,
    AngularSvgIconModule,
    FormComponent,
    FormControlDirective,
    ReactiveFormsModule,
    ButtonRectangleComponent,
    InlineToastComponent,
  ],
  templateUrl: './form-widget-permalink.component.html',
  styleUrls: ['./form-widget-permalink.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetPermalinkComponent implements OnChanges, OnInit {
  @Input() public widgetId?: string | unknown;
  @Input() public url?: string;
  @Input() public needPublish = false;
  @Input() public downloading = false;
  @Output() public download: EventEmitter<string> = new EventEmitter<string>();
  @Output() public save: EventEmitter<string> = new EventEmitter<string>();
  public hovering = false;
  public permalinkControl = new FormControl('');
  public saved = false;
  public toastTypes = ToastTypes;

  constructor(private translateService: TranslateService, private toastr: ToastrService) {}

  public get canSave(): boolean {
    return !this.saved && !this.widgetId;
  }

  public get inputDisabled(): boolean {
    return !this.permalinkControl.value;
  }

  public ngOnInit() {
    if (this.widgetId) {
      this.saved = true;
      this.permalinkControl.patchValue(this.widgetId as string);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.downloading = changes['downloading']?.currentValue ?? false;
  }

  public openLink() {
    window.open(this.link(true), '_blank');
  }

  public copyLink() {
    copyToClipboard(this.link(true));
    this.toastr.info(`${this.translateService.instant('SH.COPIED')}`);
  }

  public onDownloadClick() {
    if (!this.canSave && !this.needPublish) {
      this.downloading = true;
      this.download.emit(this.link());
    }
  }

  public onSave() {
    if (this.permalinkControl.value) {
      this.needPublish = true;
      this.saved = true;
      this.save.emit(this.permalinkControl.value);
    }
  }

  public link(full = false): string {
    if (full) {
      return `https://${this.url}/${this.widgetId}`;
    }
    return `${this.url}/${this.widgetId}`;
  }
}
