import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { IReservation, IRoom } from '@smarttypes/hotel';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, FormComponent } from '@ui/common/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { SelectOption } from 'angular-v2-utils';
import { toDate } from 'date-fns-tz';
import { first, last } from 'lodash';
import { BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BaseModalComponent } from '../base-modal/base-modal.component';

@Component({
  selector: 'ui-edit-reservation-modal',
  templateUrl: './edit-reservation-modal.component.html',
  styleUrls: ['./edit-reservation-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonRectangleComponent,
    BaseModalComponent,
    ReactiveFormsModule,
    FormComponent,
    FormControlDirective,
    ErrorMessageComponent,
    BsDatepickerModule,
    NgSelectModule,
    AngularSvgIconModule,
  ],
  providers: [DatePipe],
})
export class EditReservationModalComponent implements OnInit {
  @Input() reservation?: IReservation;
  @Input() stayRange?: string;
  @Input() rooms?: SelectOption[];
  @Input() confirmFunc?: (reservation: IReservation) => void;
  public formGroup = new FormGroup({
    room: new FormControl('', []),
    reservation: new FormControl('', [Validators.required]),
  });
  public bsConfig: Partial<BsDaterangepickerConfig> = {
    adaptivePosition: true,
    useUtc: true,
    initCurrentTime: true,
    containerClass: 'theme-sh',
    rangeInputFormat: 'DD-MM-YYYY',
    dateInputFormat: 'DD-MM-YYYY',
    showWeekNumbers: false,
    customTodayClass: 'today-picker-cell',
  };

  public get fromDate(): Date {
    return toDate(first((this.reservation?.from as unknown as string)?.split('T')) as string, {
      timeZone: 'Etc/UTC',
    });
  }

  public get toDate(): Date {
    return toDate(first((this.reservation?.to as unknown as string)?.split('T')) as string, {
      timeZone: 'Etc/UTC',
    });
  }

  constructor(public bsModalRef: BsModalRef, private datePipe: DatePipe) {}

  ngOnInit() {
    this.formGroup?.get('room')?.patchValue((this.reservation?.room as IRoom)?._id as never);

    this.formGroup?.get('reservation')?.patchValue([this.fromDate, this.toDate] as any as string);

    console.log(this.reservation);
  }

  public confirm() {
    if (this.confirmFunc) {
      const reservation: IReservation = {
        ...this.reservation,
        from: first(this.formGroup.value.reservation) as unknown as Date,
        to: last(this.formGroup.value.reservation) as unknown as Date,
        room: this.formGroup.value?.room,
      };
      this.confirmFunc(reservation);
      this.bsModalRef.hide();
    }
  }

  public onEditClick(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.confirm();
  }
}
