import { CommonModule } from '@angular/common';
import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ITag } from '@smarttypes/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subject } from 'rxjs';

@Component({
  selector: 'ui-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, NgSelectModule, AngularSvgIconModule, ReactiveFormsModule, TranslateModule],
})
export class TagsSelectComponent implements ControlValueAccessor, OnInit {
  @Input() public items: { value: string; label: string }[] | ITag[] = [];
  @Input() public bindValue!: string;
  @Input() public bindLabel!: string;
  @Input() public clearable = false;
  @Input() public multiple = false;
  @Input() public trackByFn?: () => void;
  @Input() public change?: () => void;
  @Input() public loading = false;
  @Input() public searchable = true;
  @Input() public typeToSearchText?: string;
  @Input() public typeahead!: Subject<string>;
  @Input() public groupBy = '';
  @Input() public placeholder?: string;
  @Input() public maxSelectedItems = 3;
  @Input() public formControl!: FormControl;
  @Input() public formControlName!: string;
  @Input() public readonly = false;
  @Input() public icon?: string;
  @Input() public type = 'ng-select-pill';
  @Input() public arrow = true;
  @Input() public addNew = true;
  @Input() public autoFocus = false;
  @Input() public emails = false;
  @ViewChild('select', { static: true }) public select?: NgSelectComponent;
  @ViewChild(FormControlDirective, { static: true })
  public formControlDirective?: FormControlDirective;
  private isRegister = false;

  constructor(private injector: Injector) {}

  public get control() {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  public get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  public get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  public ngOnInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.select?.open();
        this.select?.focus();
      }, 350);
    }
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  public registerOnChange(fn: any): void {
    this.isRegister = true;
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  public writeValue(obj: any): void {
    if (this.isRegister) {
      this.formControlDirective?.valueAccessor?.writeValue(obj);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
  }

  public addTagFn(name: string) {
    return name;
  }
}
