import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommunicationChannelEnum } from '@smarttypes/core';
import { IMessageStats, MessageCountTabEnum } from '@smarttypes/metrics';
import { TipComponent } from '@ui/common';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-messages-sent-by-channel-expenses-item',
  templateUrl: './messages-sent-by-channel-expenses-item.component.html',
  styleUrls: ['./messages-sent-by-channel-expenses-item.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, ButtonRectangleComponent, TipComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesSentByChannelExpensesItemComponent {
  private _stats?: IMessageStats[] | undefined;
  @Input() tooltip?: string;

  @Input() set stats(stats: IMessageStats[] | undefined) {
    this._stats = stats;
    this.setData();
    this.cdr.detectChanges();
  }

  get stats(): IMessageStats[] | undefined {
    return this._stats;
  }

  @Input() channel?: MessageCountTabEnum;
  @Input() currency = '';
  public whatsApp = '0';
  public sms = '0';
  public total = '0';

  get name(): string {
    return `SH.${this.channel?.toUpperCase()}`;
  }

  get icon(): string {
    switch (this.channel) {
      case MessageCountTabEnum.massMessages:
        return '/assets/icons/icons-message.svg';
      case MessageCountTabEnum.chat:
        return '/assets/icons/icons-chat.svg';
      case MessageCountTabEnum.automation:
        return '/assets/icons/icons-automation.svg';
      default:
        return '';
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private setData(): void {
    const calculateTotalPrice = (data: IMessageStats[]): number => {
      return data.reduce((accumulator: number, currentValue: IMessageStats) => {
        if (currentValue.price) {
          return accumulator + parseFloat(currentValue.price?.split(' ')[0]);
        }
        return accumulator;
      }, 0);
    };

    this.total = (calculateTotalPrice(this.stats || []) || 0).toFixed(1);

    this.sms = (
      calculateTotalPrice(this.stats?.filter(item => item.channel === CommunicationChannelEnum.Sms) || []) || 0
    ).toFixed(1);

    this.whatsApp = (
      calculateTotalPrice(this.stats?.filter(item => item.channel === CommunicationChannelEnum.WhatsApp) || []) || 0
    ).toFixed(1);
  }
}
