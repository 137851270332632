import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export interface IStepper {
  id: number | string;
  label: string;
  canActivate: boolean;
}

@Component({
  selector: 'ui-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnInit, OnChanges {
  @Output() step: EventEmitter<IStepper> = new EventEmitter<IStepper>();
  @Input() steps: IStepper[] = [];
  @Input() blockClick = false;
  @Input() currentStep?: IStepper;

  public get currentStepIndex(): number {
    return this.steps.findIndex(s => s.id === this.currentStep?.id);
  }

  public ngOnInit(): void {
    this.currentStep = this.steps[0];
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['currentStep']?.currentValue) {
      this.currentStep = changes['currentStep'].currentValue as IStepper;
    }
  }

  public onStepClick(item: IStepper) {
    const step = this.steps.find(step => step.id === item.id) as IStepper;
    if (!item?.canActivate) {
      return;
    }
    if (this.blockClick) {
      this.step.emit(step);
      return;
    }
    this.currentStep = step;
    this.step.emit(this.currentStep);
  }
}
