import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { set } from 'lodash';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'ui-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgxTippyModule, AngularSvgIconModule],
})
export class TipComponent implements OnInit {
  @HostBinding('class') @Input() public placement:
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end' = 'right-start';
  @Input() public maxWidth = '376px';
  @Input() public icon: 'icons-help' | 'icons-coin' = 'icons-help';
  @Input() public offset: [number, number] = [0, 0];
  @Input() public config?: NgxTippyProps;
  public defaultConfig: NgxTippyProps = {
    duration: [500, 500],
    arrow: false,
    theme: 'tip',
    allowHTML: true,
    appendTo: 'parent',
    interactive: true,
    interactiveBorder: 10,
    animation: 'scale',
  };

  public ngOnInit() {
    set(this.defaultConfig, 'placement', this.placement);
    set(this.defaultConfig, 'maxWidth', this.maxWidth);
    set(this.defaultConfig, 'offset', this.offset);
  }
}
