import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IRoom } from '@smarttypes/hotel';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { CheckboxComponent, FormComponent } from '@ui/common/forms';
import { FormControlDirective } from 'angular-v2-directives';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BaseModalComponent } from '../base-modal/base-modal.component';

@Component({
  selector: 'ui-linked-objects-modal',
  templateUrl: './linked-objects-modal.component.html',
  styleUrls: ['./linked-objects-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    FormControlDirective,
    ButtonRectangleComponent,
    FormComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    BaseModalComponent,
  ],
})
export class LinkedObjectsModalComponent implements OnInit, OnDestroy {
  @Input() public $objects!: Observable<{ value: string; label: string }[]>;
  @Input() public objects: { value: string; label: string }[] = [];
  @Input() public roomIds: string[] = [];
  @Output() public save: EventEmitter<string[]> = new EventEmitter<string[]>();
  public loading = true;
  public rooms: IRoom[] = [];
  public form: FormGroup = new FormGroup({
    selectAll: new FormControl(false),
    rooms: new FormGroup({}),
  });
  private subscriptions: Subscription = new Subscription();

  public ngOnInit() {
    this.subscriptions.add(
      this.$objects.pipe(tap(objects => (this.objects = objects))).subscribe(() => {
        this.generateFormFields();
        this.loading = false;
      }),
    );

    this.subscriptions.add(
      this.form
        .get('rooms')
        ?.valueChanges.pipe(map(rooms => Object.keys(rooms).filter(k => rooms[k])))
        .subscribe(ids => {
          this.roomIds = ids;
        }),
    );

    this.subscriptions.add(
      this.form.get('selectAll')?.valueChanges.subscribe(state => {
        this.objects.map(obj => {
          this.form.get('rooms')?.get(obj.value)?.setValue(state, {
            eventEmit: false,
          });
        });
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getRoomControlAt(object: { value: string; label: string }): FormControl {
    return this.form.get('rooms')?.get(object.value) as FormControl;
  }

  public onSave() {
    this.save.emit(this.roomIds);
  }

  private generateFormFields(): void {
    this.objects.forEach(obj => {
      (this.form.get('rooms') as FormGroup).addControl(obj.value, new FormControl(this.roomIds.includes(obj.value)), {
        emitEvent: false,
      });
    });
  }
}
