import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ICountry, ILanguage } from '@smarttypes/core';
import { QuestionTypeEnum } from '@smarttypes/registration-card';
import { SelectOption } from 'angular-v2-utils';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { FormQuestion } from '../checkin-form.component';

@Component({
  selector: 'sh-checkin-form-question',
  templateUrl: './checkin-form-question.component.html',
  styleUrls: ['./checkin-form-question.component.scss'],
})
export class CheckinFormQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public question?: FormQuestion;
  @Input() public languages?: ILanguage[];
  @Input() public countries?: ICountry[];
  @Input() public form?: FormGroup;
  @Input() public preview = false;
  @Input() public translatePrefix: 'GA' | 'SH' = 'GA';
  @Output() public loadMoreRooms = new EventEmitter<{
    term: string;
    initial: boolean;
  }>();
  public questionTypeEnum = QuestionTypeEnum;
  public bsConfig: Partial<BsDaterangepickerConfig> = {
    adaptivePosition: true,
    // useUtc: true,
    initCurrentTime: true,
    containerClass: 'theme-sh',
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    customTodayClass: 'today-picker-cell',
  };
  public yesNo: { value: boolean; displayName: string }[] = [];
  private _rooms: SelectOption[] = [];
  @Input()
  public set rooms(rooms: SelectOption[]) {
    this._rooms = rooms;
  }

  public get rooms(): SelectOption[] {
    return this._rooms;
  }

  private _roomLoading = false;
  @Input()
  public set roomLoading(roomLoading: boolean) {
    this._roomLoading = roomLoading;
  }

  public get roomLoading(): boolean {
    return this._roomLoading;
  }

  public roomInput$ = new Subject<string>();
  public subscription = new Subscription();
  private roomSearchTerm = '';

  constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {}

  public get isRequired(): boolean {
    return this?.question?.required || false;
  }

  public get isGuestArea(): boolean {
    return this.translatePrefix === 'GA';
  }

  public ngOnInit(): void {
    this.yesNo = [
      {
        value: false,
        displayName: this.translateService.instant(`${this.translatePrefix}.NO`),
      },
      {
        value: true,
        displayName: this.translateService.instant(`${this.translatePrefix}.YES`),
      },
    ];
  }

  public searchInput() {
    return this.roomInput$.pipe(
      filter(res => {
        this.roomSearchTerm = res;
        const count = res?.length ?? 0;
        return count >= 3 || count === 0;
      }),
      debounceTime(800),
      tap(() => this.onLoadMoreRooms(true)),
    );
  }

  public onLoadMoreRooms(initial = false): void {
    this.loadMoreRooms.emit({ term: this.roomSearchTerm, initial: initial });
  }

  public ngAfterViewInit() {
    if (this.question?.type === QuestionTypeEnum.apartmentNumber) {
      this.onLoadMoreRooms(true);

      this.subscription.add(
        this.searchInput().subscribe(() => {
          this.cdr.detectChanges();
        }),
      );
    }
  }

  public addTag(name: string) {
    return { label: name, value: name };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
