import { BASE_ENV, IBaseEnv } from './base-env';

export const environment: IBaseEnv = {
  ...BASE_ENV,
  poweredBy: false,
  production: true,
  releaseName: 'smart-core',
  releasePhase: 'production',
  sentryDns: undefined, // 'https://5c6b0159ffe71b2fa16ca66d10f2b9f3@o321561.ingest.us.sentry.io/4507571770818560',
  intercomAppId: 'sx1gvhdt',
  intercomEnvironment: 'production',
  registrationEnabled: true,
  canEditRooms: true,
  hasLeads: false,
  mediaLibrary: 'https://smart-core-api.smarthotelapi.com/media-library',
  appViewUrl: 'https://app-views-gjpvjwn2zq-ew.a.run.app',
  googleKey: 'AIzaSyCD7gTxbQ7a0SFNSgTzL_WfcCdvGtcrzrc',
  apiUrl: 'https://smart-core-api.smarthotelapi.com/',
  gtmId: 'GTM-KC2HX8D',
  i18n: {
    force: false,
  },
  jwt: {
    allowedDomains: ['localhost:4200', 'smart-admin-test.mysmarthotel.com'],
    disallowedRoutes: [
      new RegExp(/smart-core-public-api/),
      new RegExp(/account\/login/),
      new RegExp(/account\/refresh/),
      new RegExp(/assets/),
    ],
  },
  modules: {
    pushNotifications: {
      enabled: true,
    },
  },
  smartChatDemoUrl: 'https://sh-smart-ai-demo-page.web.app',
  appMobileUrl: 'https://sh-core-system-mobile.web.app',
  ui: {
    user: {
      login: {
        showSelectWhatsApp: true,
      },
    },
    canShowUpgradeArrow: true,
    showAppsBadge: true,
    flatRoomName: false,
    top: {
      enabled: true,
      account: true,
    },
    sidebar: {
      enabled: true,
      companySwitcher: true,
    },
    unifiedInbox: {
      header: true,
      createRequest: true,
      guests: true,
      payments: true,
      sendTemplate: true,
      reservationStatus: false,
      assignUser: true,
    },
    qrMaterials: {
      identification: true,
      editLink: true,
    },
    massMessaging: {
      filters: {
        status: ['arrivals', 'inHouse', 'checkedOut'],
        tags: true,
        nationality: true,
        rooms: false,
        guestAddDate: true,
        marketingConsents: true,
      },
    },
    requests: {
      filters: {
        category: true,
        department: false,
        commonName: false,
        ident: false,
      },
    },
    visitorsPlaceModule: {
      enabled: true,
    },
    showVisitorRoomInfo: false,
    smartAutomation: {
      customEnable: true,
      forceCheckinEnable: false,
      skipRoomCheck: false,
      settings: {
        enable: true,
        entryInstruction: true,
        channels: true,
      },
    },
    guests: {
      reservationStatus: true,
      editable: true,
      sendEntryCodes: true,
      journeyDetails: true,
      multipleReservations: true,
      forceActionButtonsInList: false,
    },
    apartmentEditor: {
      customSectionsEnable: true,
      syncButton: true,
      requiredFieldsWarning: true,
      importInformation: true,
      copyFeature: true,
      validation: true,
      requiredFields: [
        'apartmentNumber',
        'floorNumber',
        'country',
        'postalCode',
        'city',
        'streetAndHouseNumber',
        'googleMapsPin',
        'checkInFromTime',
        'checkOutUntilTime',
        'wifiName',
        'entryInstruction',
      ],
      fields: {
        base: [
          'apartmentNumber',
          'floorNumber',
          'country',
          'postalCode',
          'city',
          'streetAndHouseNumber',
          'googleMapsPin',
          'checkInFromTime',
          'checkInToTime',
          'checkOutUntilTime',
          'wifiName',
          'wifiPassword',
        ],
        stay: ['about', 'directions', 'amenities', 'parking', 'transfer', 'meals', 'cleaning', 'website'],
      },
    },
  },
  registerPushNotifications: true,
  firebaseCreds: {
    apiKey: 'AIzaSyALqzEpBfylk0Cl_odQSr8QZtz_cHdN-dQ',
    authDomain: 'sh-core-system.firebaseapp.com',
    projectId: 'sh-core-system',
    storageBucket: 'sh-core-system.appspot.com',
    messagingSenderId: '816977376109',
    appId: '1:816977376109:web:eb684ec8b5cdf2990ea064',
    measurementId: 'G-QKLWZ2PM0D',
  },
};
