import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ui-iphone-frame',
  templateUrl: './iphone-frame.component.html',
  styleUrls: ['./iphone-frame.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IphoneFrameComponent {
  @Input() public url?: string;
  @Input() public placeholder?: string;
}
