import { format, isSameMonth, isSameYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { setLocale } from './date-fns-set-locale';

export function stayRange(
  dateFrom: Date | string | undefined,
  dateTo: Date | string | undefined,
  timezone = 'UTC',
  timeFrom?: string,
  timeTo?: string,
  language?: string,
): string {
  if (!language) {
    language = 'en';
  }
  if (!dateFrom || !dateTo) {
    return '';
  }
  const from: Date = utcToZonedTime(dateFrom as string, timezone);
  const to: Date = utcToZonedTime(dateTo as string, timezone);

  if (!from && !to) {
    return '';
  }

  const startDate = format(from, 'MMM d', {
    locale: setLocale(language),
  });
  const startTime = timeFrom ? '(' + timeFrom + ')' : '';
  const endDate = format(to, 'MMM d', {
    locale: setLocale(language),
  });
  const endTime = timeTo ? '(' + timeTo + ')' : '';

  if (isSameYear(from, to)) {
    if (isSameMonth(from, to)) {
      return `${startDate}${startTime} - ${format(to, 'd')}${endTime}`;
    } else {
      return `${startDate}${startTime} - ${endDate}${endTime}`;
    }
  } else {
    return `${startDate}${startTime} - ${endDate}${endTime}`;
  }
}
