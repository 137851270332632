import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OwnerPathInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('googleapis') && ['get', 'post'].includes(request.method.toLowerCase())) {
      const req = request.clone({
        setHeaders: {
          'owner-path': window.location.pathname,
        },
      });
      return next.handle(req).pipe(
        catchError(error => this.catchError(error)),
        map(event => this.processEvent(event)),
      );
    }
    return next.handle(request);
  }

  private catchError(event: HttpEvent<HttpErrorResponse>): Observable<HttpEvent<HttpErrorResponse>> {
    this.doRedirect(event);
    throw event;
  }

  private processEvent(event: HttpEvent<unknown>): HttpEvent<unknown> {
    this.doRedirect(event);
    return event;
  }

  private doRedirect(event: HttpResponse<unknown> | HttpEvent<HttpErrorResponse>) {
    const redirectionStatusCodes = [301, 302, 200];
    if (redirectionStatusCodes.includes(get(event, 'status', 0))) {
      const redirectUrl = get(event, 'body.redirect', '') as string;
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      }
    }
  }
}
