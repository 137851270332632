import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { hasCommunicationChannel, stayRange } from '@smarthotel/utils';
import { CommunicationChannelEnum } from '@smarttypes/core';
import { IGuest, IReservation } from '@smarttypes/hotel';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ngIfDropdownOpenTrigger } from 'angular-v2-animations';
import { endOfDay, getYear, isBefore, isSameYear, isWithinInterval, parseISO, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { get } from 'lodash';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ReservationClickActionEnum } from '../pasts-visits-vertical/pasts-visits-vertical.component';

@Component({
  selector: 'ui-pasts-visits-vertical-item',
  templateUrl: './pasts-visits-vertical-item.component.html',
  styleUrls: ['./pasts-visits-vertical-item.component.scss'],
  standalone: true,
  imports: [CommonModule, DatePipe, ButtonCircleComponent, BsDropdownModule, TranslateModule, AngularSvgIconModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ngIfDropdownOpenTrigger],
})
export class PastsVisitsVerticalItemComponent {
  @Input() reservation?: IReservation;
  @Input() currentReservationId = '';
  @Input() showArrows = false;
  @Input() visitor?: IGuest;
  @Output() reservationClick: EventEmitter<{
    reservation: IReservation;
    action: ReservationClickActionEnum;
    isActive: boolean;
  }> = new EventEmitter<{
    reservation: IReservation;
    action: ReservationClickActionEnum;
    isActive: boolean;
  }>();
  reservationClickActionEnum = ReservationClickActionEnum;

  constructor(private translateService: TranslateService) {}

  get isGuestImported(): boolean {
    return !!(this.visitor?.initialValue as any)?.dataFromLastImport;
  }

  get canShowSendSecrets(): boolean {
    return (
      hasCommunicationChannel(this.visitor, CommunicationChannelEnum.Email) ||
      hasCommunicationChannel(this.visitor, CommunicationChannelEnum.Phone)
    );
  }

  get isCurrent(): boolean {
    return this.currentReservationId === this.reservation?.reservationId;
  }

  get isNotCurrentYear(): boolean {
    return this.currentYear !== getYear(new Date(this.reservation?.from || ''));
  }

  get currentYear(): number {
    const currentDate = new Date();
    return getYear(currentDate);
  }

  get isSameYear(): boolean {
    if (!this.reservation?.from || !this.reservation?.to) {
      return false;
    }
    const from: Date = this.fromDate;
    const to: Date = this.toDate;

    if (!from && !to) {
      return false;
    }

    return isSameYear(from, to);
  }

  get isActive(): boolean {
    const fromString = get(this.reservation, 'from', '') as string;
    const toString = get(this.reservation, 'to', '') as string;

    if (!fromString && !toString) {
      return false;
    }

    const today = new Date();

    return isWithinInterval(today, {
      start: startOfDay(new Date(fromString)),
      end: endOfDay(new Date(toString)),
    });
  }

  get isPast(): boolean {
    const fromString = get(this.reservation, 'from', '') as string;

    const currentDate = new Date();
    const from = parseISO(fromString);

    return isBefore(from, currentDate);
  }

  get isPastYear(): boolean {
    const currentDate = new Date();
    const currentYear = getYear(currentDate);
    const reservationYear = getYear(new Date(this.reservation?.from || ''));

    return currentYear > reservationYear;
  }

  get fromDate(): Date {
    return utcToZonedTime(this.reservation?.from as unknown as string, 'UTC');
  }

  get toDate(): Date {
    return utcToZonedTime(this.reservation?.to as unknown as string, 'UTC');
  }

  get stayRange(): string {
    return stayRange(this.reservation?.from, this.reservation?.to, 'UTC', '', '', this.translateService.currentLang);
  }

  onReservationClick(action: ReservationClickActionEnum): void {
    this.reservationClick.emit({
      reservation: this.reservation as IReservation,
      action: action,
      isActive: this.isActive,
    });
  }
}
