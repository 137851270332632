import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CheckComponent } from './check/check.component';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, CheckComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, AfterViewInit {
  @Input() value = false;
  @Input() text?: string;
  @Input() id?: string;
  @Input() required = false;
  @Input() lines = 2;
  @Input() isGA = false;
  @Input() isExpandable = false;
  @Input() smallGap = false;
  @HostBinding('class.space-around') @Input() spaceAround = false;
  @HostBinding('class.disabled') disabled = false;
  @HostBinding('class.expanded') expanded = false;
  @HostBinding('class.collapsed') isCollapsed = false;
  @ViewChild('textElement') textElement?: ElementRef<HTMLElement>;
  private isRegister = false;

  constructor(private cdr: ChangeDetectorRef) {}

  get checkboxValue() {
    return this.value;
  }

  set checkboxValue(val: any) {
    this.value = val;
    this.emitChange();
  }

  get translationPrefix(): string {
    return this.isGA ? 'GA.' : 'SH.';
  }

  onChange = (_: any) => {
    console.log();
  };

  public ngOnInit() {
    this.emitChange();
  }

  public ngAfterViewInit() {
    if (this.isExpandable) {
      setTimeout(() => {
        this.lines = this.countLines();
        this.isCollapsed = true;
        this.cdr.detectChanges();
      }, 100);
    }
  }

  public emitChange() {
    if (!this.disabled && this.isRegister) {
      this.onChange(this.value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    this.isRegister = true;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public toggle() {
    this.expanded = !this.expanded;
  }

  public onExpandClick(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public countLines(): number {
    const el = this.textElement?.nativeElement;

    if (el) {
      const divHeight = el.offsetHeight;
      return divHeight / 22;
    }

    return 0;
  }
}
