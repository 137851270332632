import { Injectable } from '@angular/core';
import { utcToTimezone } from '@smarthotel/utils';
import { VaWidgetCode } from '@smarttypes/core';
import { convertTimeFrom24hTo12h } from 'angular-v2-utils';
import { addDays, differenceInMinutes, isAfter, isBefore, set } from 'date-fns';
import { first, get, isBoolean, isSet, last } from 'lodash';

import { DayNames, IWidgetOpenDay, WidgetOpenDaysEnum, WidgetOpenGroupDays } from './opening-hours.interfaces';

@Injectable({
  providedIn: 'root',
})
export class OpeningHoursService {
  private data?: any;
  private nextDayName = '';
  private dayNames = DayNames;
  private isOpen = false;
  private todayWillBeOpen = false;
  private currentObject: IWidgetOpenDay | undefined = undefined;
  private soonBeClosed = false;
  private timezone?: string;
  private widgetCode?: string;
  private v2 = false;
  private amPm = false;

  get eachDays(): boolean {
    return this.data?.eachDay ?? false;
  }

  get is24h(): boolean {
    return this.data?.is24h ?? false;
  }

  get useAmPm(): boolean {
    return this.amPm && this.v2 && this.widgetCode !== 'working-hours';
  }

  get soonBeClosedTitle(): string {
    switch (this.widgetCode) {
      case VaWidgetCode.meals:
      case VaWidgetCode.spa:
      case VaWidgetCode.attractions:
      case VaWidgetCode.gastronomy:
        return 'GA.SOON_BE_CLOSED_2';
      default:
        return 'GA.SOON_BE_CLOSED_1';
    }
  }

  private get now(): Date {
    if (this.timezone) {
      return utcToTimezone(new Date(), this.timezone) as Date;
    }
    return new Date();
  }

  private get weekDaysStartFromToday(): string[] {
    const todayIndex = this.now.getDay();
    const weekDays = [...this.dayNames];
    const pastDays = weekDays.splice(todayIndex);
    return [...pastDays, ...weekDays];
  }

  private get isTomorrow(): boolean {
    const todayIndex = this.weekDaysStartFromToday.findIndex(d => d.toLowerCase() === this.getCurrentDayName());
    return this.nextDayName === this.weekDaysStartFromToday[todayIndex + 1];
  }

  // @TODO refactor
  getOpenDays(openingHours: any, timezone?: string, widgetCode?: string, v2?: boolean, amPm?: boolean): any {
    this.data = openingHours;
    if (timezone) {
      this.timezone = timezone;
    }
    if (widgetCode) {
      this.widgetCode = widgetCode;
    }
    this.v2 = isBoolean(v2) ? v2 : false;
    this.amPm = isSet(amPm) ? amPm : false;
    this.initWorkingDays();

    return {
      openFrom: this.currentObject?.from ?? '',
      openTo: this.currentObject?.to ?? '',
      isOpen: this.isOpen,
      todayWillBeOpen: this.todayWillBeOpen,
      nextDayName: this.nextDayName,
      soonBeClosed: this.soonBeClosed,
      isTomorrow: this.isTomorrow,
      groupDays: this.groupDaysByOpeningHours(),
      label: this.getCurrentLabel(),
    };
  }

  public getCurrentLabel(): {
    label: string;
    class: string;
    from?: string;
    to?: string;
  } | null {
    if (this.is24h) {
      return {
        label: `GA.OPEN_24H_DAY`,
        class: 'open-now',
      };
    }
    if (this.soonBeClosed) {
      return {
        label: this.soonBeClosedTitle,
        class: 'soon-be-closed',
      };
    } else if (this.isOpen) {
      return {
        label: `GA.NOW`,
        to: this.currentObject?.to ?? '',
        class: 'now',
      };
    } else if (this.todayWillBeOpen) {
      return {
        label: `GA.TODAY`,
        from: this.currentObject?.from ?? '',
        to: this.currentObject?.to ?? '',
        class: 'today-will-be-open',
      };
    } else if (this.nextDayName && this.isTomorrow) {
      return {
        label: `GA.TOMORROW`,
        from: this.currentObject?.from ?? '',
        to: this.currentObject?.to ?? '',
        class: 'tomorrow',
      };
    } else if (this.nextDayName) {
      return {
        label: `GA.${this.nextDayName.toUpperCase()}`,
        from: this.currentObject?.from ?? '',
        to: this.currentObject?.to ?? '',
        class: 'day',
      };
    } else {
      return null;
    }
  }

  private checkSoonBeClosed(openTo: Date): boolean {
    if (!this.isOpen) {
      return false;
    }
    const diff = differenceInMinutes(openTo, this.now);
    return diff <= 30 && diff >= 0;
  }

  private timeToNumber(time: string): number {
    return parseInt(time?.replace(':', ''));
  }

  private getCurrentDayName() {
    const today = this.now.getDay();
    return this.dayNames[today].toLowerCase();
  }

  private initWorkingDays() {
    const days: Record<string, IWidgetOpenDay> = this.workDaysToDateTime();
    const currentDay = days[this.getCurrentDayName()];
    const currentDayFromNumber = this.timeToNumber(currentDay.from);
    const currentDayToNumber = this.timeToNumber(currentDay.to);
    const closeNextDay = currentDayFromNumber > currentDayToNumber;
    const openAllDay = currentDayFromNumber === currentDayToNumber;
    const dateFrom = this.createDateWithGivenTime(currentDay.from);
    const dateTo = this.createDateWithGivenTime(currentDay.to, closeNextDay, openAllDay);

    if (currentDay.isOpen && !isBefore(this.now, dateFrom) && !isAfter(this.now, dateTo)) {
      this.currentObject = currentDay;
      this.isOpen = true;
      this.todayWillBeOpen = false;
    } else if (currentDay.isOpen && isBefore(this.now, dateFrom)) {
      this.isOpen = false;
      this.todayWillBeOpen = true;
      this.currentObject = currentDay;
    } else {
      this.isOpen = false;
      this.todayWillBeOpen = false;
      this.currentObject = this.getNextAvailableDay(days);
    }
    if (this.currentObject) {
      this.convertTimeTo12hFormat(this.currentObject);
    }
    this.soonBeClosed = this.checkSoonBeClosed(dateTo);
  }

  private createDateWithGivenTime(time: string, nextDay = false, allDay = false): Date {
    const date = nextDay ? addDays(this.now, 1) : this.now;
    let params: { hours: number; minutes: number; seconds: number };

    if (!time) {
      params = { hours: 0, minutes: 0, seconds: 0 };
    } else if (allDay) {
      params = { hours: 23, minutes: 59, seconds: 59 };
    } else {
      const [hours, minutes] = time.split(':').map(Number);
      params = { hours, minutes, seconds: 0 };
    }

    return set(date, params);
  }

  private getNextAvailableDay(data: Record<string, IWidgetOpenDay>): IWidgetOpenDay | undefined {
    let allDaysChecked = false;
    const weekDays = this.weekDaysStartFromToday;

    for (let i = 1; i <= weekDays.length + 1; i++) {
      const d = weekDays[i];
      if (!d) {
        this.nextDayName = '';
        return undefined;
      }
      const day = get(data, `${d.toLowerCase()}`);

      if (day.isOpen) {
        this.nextDayName = d;
        this.isOpen = false;

        if (this.isTomorrow) {
          this.nextDayName = 'TOMORROW';
        }
        return day;
      }

      if (i === weekDays.length) {
        i = -1;
        if (allDaysChecked) {
          return undefined;
        }
        allDaysChecked = true;
      }
    }

    return undefined;
  }

  private workDaysToDateTime() {
    return Object.keys(WidgetOpenDaysEnum).reduce((aggr: Record<string, IWidgetOpenDay>, day: string) => {
      aggr[day] = this.convertPayloadToOpenDayObject(day);
      return aggr;
    }, {} as Record<string, IWidgetOpenDay>);
  }

  private convertPayloadToOpenDayObject(day: string) {
    const defaultTime = '';
    if (this.v2) {
      const dayName = this.eachDays ? day : day === 'saturday' || day === 'sunday' ? 'weekDays' : 'workDays';

      return {
        isOpen: get(this.data, `${dayName}.isOpen`, false),
        from: get(this.data, `${dayName}.from`, defaultTime),
        to: get(this.data, `${dayName}.to`, defaultTime),
        day,
      };
    } else {
      if (this.eachDays) {
        return {
          isOpen: get(this.data, `days.${day}`, false),
          from: get(this.data, `days.${day}From`, defaultTime),
          to: get(this.data, `days.${day}To`, defaultTime),
          day,
        };
      } else {
        const dayName = day === 'saturday' || day === 'sunday' ? 'weekend' : 'week';
        const from = get(this.data, `${dayName}From`, defaultTime);
        const to = get(this.data, `${dayName}To`, defaultTime);

        return {
          isOpen: from !== defaultTime && to !== defaultTime,
          from,
          to,
          day,
        };
      }
    }
  }

  private groupDaysByOpeningHours(): WidgetOpenGroupDays[] {
    let previousDay: IWidgetOpenDay | undefined;

    return Object.values(this.workDaysToDateTime())
      .reduce((groupedDays: WidgetOpenGroupDays[], currentDay: IWidgetOpenDay) => {
        if (
          previousDay &&
          previousDay.from === currentDay.from &&
          previousDay.to === currentDay.to &&
          previousDay.isOpen === currentDay.isOpen
        ) {
          const existingGroup = groupedDays.find((group: WidgetOpenGroupDays) =>
            group.days.some((day: IWidgetOpenDay) => day.day === previousDay?.day),
          );

          existingGroup?.days?.push(currentDay);
        } else {
          groupedDays.push({
            ...currentDay,
            days: [currentDay],
          });
        }

        previousDay = currentDay;
        return groupedDays;
      }, [])
      .map(group => {
        const firstDayLabel = `GA.${first(group.days)?.day?.toUpperCase()}`;
        const lastDayLabel = `GA.${last(group.days)?.day?.toUpperCase()}`;

        this.convertTimeTo12hFormat(group);

        if (group.days.length === 0) {
          return group;
        } else if (group.days.length > 1) {
          group.label = `${firstDayLabel} - ${lastDayLabel}`;
          group.labelFrom = firstDayLabel;
          group.labelTo = lastDayLabel;
        } else {
          group.label = `${firstDayLabel}`;
          group.labelFrom = firstDayLabel;
        }

        return group;
      });
  }

  private convertTimeTo12hFormat(widgetObject: IWidgetOpenDay | WidgetOpenGroupDays) {
    if (!this.useAmPm) {
      return widgetObject;
    }
    if (widgetObject?.from) {
      widgetObject.from = convertTimeFrom24hTo12h(widgetObject.from);
    }
    if (widgetObject?.to) {
      widgetObject.to = convertTimeFrom24hTo12h(widgetObject.to);
    }
    return widgetObject;
  }
}
