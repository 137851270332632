import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { IRoom } from '@smarttypes/hotel';

@Component({
  selector: 'ui-room-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './room-badge.component.html',
  styleUrls: ['./room-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomBadgeComponent implements OnInit {
  @Input() roomName?: string;
  @Input() roomNumber?: string;
  @Input() type: 'number' | 'name' = 'number';
  @Input() direction: 'left' | 'right' = 'left';
  @Input() room?: IRoom;
  @Input() showFull = true;
  @Input() @HostBinding('class.small') small = false;

  public get directionClass() {
    return `room-badge__circle--${this.direction}`;
  }

  public get hasName(): boolean {
    return !!this.roomName;
  }

  public get isRight(): boolean {
    return this.direction === 'right';
  }

  ngOnInit() {
    if (this.room) {
      this.roomNumber = this.room.roomNo;
      this.roomName = this.room.roomType;
    }
  }
}
