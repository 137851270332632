import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private topNavEnable = environment?.ui?.topNavigation?.enabled ?? false;
  private topNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.topNavEnable);

  get isTopNavigationVisible(): boolean {
    return this.topNavigation$.value && this.topNavEnable;
  }

  setTopNavVisibility(state: boolean) {
    this.topNavigation$.next(state);
  }
}
