import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ITab } from '@smarttypes/core';
import { get } from 'lodash';

@Component({
  selector: 'ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class TabsComponent implements AfterViewInit, OnChanges {
  @Input() tabs: ITab[] = [];
  @Input() activeTab: string | null = null;
  @Input() allowNoActiveTab = false;
  @Input() counter = true;
  @Input() blockClick = true;
  @HostBinding('class') @Input() size?: string;
  @Output() tabClick: EventEmitter<ITab> = new EventEmitter();
  @ViewChildren('tabElement') public tabElement?: QueryList<ElementRef>;
  public tabWidth = 0;
  public leftPosition = 0;
  public enableAnimation = false;

  public get allowedTabs(): ITab[] {
    return this.tabs?.reduce((tabs: ITab[], tab) => {
      if (this.activeTab === tab.key || !tab.hideWhenZero || (tab?.items ?? 0) > 0 || tab?.persist) {
        tabs.push(tab);
      }
      return tabs;
    }, [] as ITab[]);
  }

  public ngAfterViewInit() {
    this.setInitialTab();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const activatedTab = changes['activeTab'];
    if (!activatedTab?.firstChange && activatedTab?.currentValue !== activatedTab?.previousValue) {
      this.activatedTab(activatedTab.currentValue);
    }
  }

  public onItemClick(tab: ITab | null) {
    if (!tab) {
      return;
    }
    if (this.blockClick) {
      this.tabClick.emit(tab);
      return;
    }
    this.activatedTab(tab.key);
    this.tabClick.emit(tab);
  }

  private setInitialTab() {
    if (this.activeTab) {
      this.onItemClick(this.allowedTabs?.find(t => t.key === this.activeTab) ?? null);
    } else if ((this.allowedTabs?.length ?? 0) > 0) {
      this.onItemClick(get(this.tabs, `0`, null));
    }
    setTimeout(() => {
      this.enableAnimation = true;
    }, 300);
  }

  private activatedTab(key: string) {
    this.activeTab = key;
    const index = this.allowedTabs?.findIndex(t => t.key === this.activeTab) ?? 0;
    const tabs = this.tabElement?.toArray() as ElementRef[];
    this.leftPosition = tabs[index]?.nativeElement?.offsetLeft ?? 0;
    this.tabWidth = tabs[index]?.nativeElement?.clientWidth ?? 120;
  }
}
