import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TipComponent, UploaderClass, UploaderComponent } from '@ui/common';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageCropperComponent, ImageCropperModule } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';

import { BaseModalComponent } from '../base-modal/base-modal.component';

@Component({
  selector: 'ui-logo-picker-modal',
  templateUrl: './image-picker-modal.component.html',
  styleUrls: ['./image-picker-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    BaseModalComponent,
    ButtonRectangleComponent,
    UploaderComponent,
    ImageCropperModule,
    TipComponent,
  ],
})
export class ImagePickerModalComponent implements OnInit, OnDestroy {
  @ViewChild(ImageCropperComponent) public imageCropper!: ImageCropperComponent;
  @Input() public fileEditOriginalUrl = '';
  @Input() public blackBg = true;
  @Input() public tip = '';
  @Input() public formats = '.png,.svg';
  @Input() public header = 'SH.LOGO_PICKER.HEADER';
  @Input() public subtitle = 'SH.LOGO_PICKER.DESCRIPTION';
  @Input() public uploaderDescription = 'SH.UPLOADER.DESCRIPTION';
  @Input() public lockedRatio = true;
  @Input() public aspectRatio = 1 / 1;
  @Input() public maxSize = 10485760;
  @Input() public minWidth = 0;
  @Input() public minHeight = 0;
  @Input() public isLogo = false;
  @Input() public canReplace = false;
  @Input() public allowEdit = true;

  @Output() public fileChange = new EventEmitter();
  public croppedImage?: string;
  public pending = false;
  public file?: File;
  public fileUrl = '';
  public fileOriginalUrl = '';
  private subscriptions: Subscription = new Subscription();

  constructor(public options: ModalOptions, private modalService: BsModalRef) {}

  public get logoMode(): boolean {
    return this.isLogo && !!this.file && !this.isSvg;
  }

  public get isSvg(): boolean {
    return this.file?.type === 'image/svg+xml' || this.fileEditOriginalUrl?.includes('image/svg+xml');
  }

  public get headerTitle(): string {
    if (!this.allowEdit) {
      return !this.logoMode ? 'SH.LOGO_PICKER.HEADER' : 'SH.LOGO_PICKER.HEADER.MANAGE';
    }
    return this.header;
  }

  ngOnInit() {
    if (this.fileEditOriginalUrl) {
      UploaderClass.toDataUrl(this.fileEditOriginalUrl).then(dataUrl => {
        this.file = UploaderClass.dataURLtoFile(dataUrl, this.fileEditOriginalUrl);
      });
    }
  }

  public onFileAdded(file: File): void {
    this.file = file;
    if (this.isSvg) {
      this.save();
    }
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 as string;
  }

  public crop() {
    this.imageCropper.crop();
    this.save();
  }

  public delete(): void {
    this.fileChange.emit(null);
    this.modalService.hide();
  }

  public save(): void {
    if (this.pending) {
      return;
    }
    this.fileChange.emit(this.croppedImage);
    this.modalService.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onChangeLogoClick() {
    this.file = undefined;
    this.fileUrl = '';
    this.fileEditOriginalUrl = '';
    this.allowEdit = true;
  }
}
