import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiValidatorService } from '@smarthotel/angular-services';
import { IAccount } from '@smarttypes/core';
import { untilDestroyed } from 'angular-v2-utils';
import { set } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AccountService } from '../../core/services/account.service';

@Component({
  selector: 'sh-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WelcomeModalComponent implements OnInit, OnDestroy {
  @Input() account!: IAccount;
  public pending = false;
  public isSent = false;
  public avatar!: string;
  public form: FormGroup = new FormGroup(
    {
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, this.apiValidator.validateEmail]),
    },
    { updateOn: 'submit' },
  );
  private subscription: Subscription = new Subscription();

  constructor(
    private apiValidator: ApiValidatorService,
    private bsRef: BsModalRef,
    private readonly accountService: AccountService,
    private readonly toast: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.account) {
      this.form.patchValue({
        firstName: this?.account?.person?.firstName,
        lastName: this?.account?.person?.lastName,
        email: this.account?.email,
      });

      if (this.account?.email) {
        this.form.get('email')?.disable();
      }

      this.avatar = this.account?.avatar?.avatar || '';

      if (this.account?.email && this?.account?.person?.firstName && this?.account?.person?.firstName) {
        this.isSent = true;
      }
    }
    this.subscription.add(
      this.accountService
        .$currentUser()
        .pipe(untilDestroyed(this))
        .subscribe(account => {
          if (account?.person?.firstName && account?.person?.lastName && account?.email && account?.emailConfirmed) {
            this.bsRef.hide();
          }
        }),
    );
  }

  submit() {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;

    const data: Partial<IAccount> = {
      person: {
        ...this.form.value,
      },
      email: this.form.get('email')?.value,
    };
    if (this.avatar) {
      set(data, 'avatar.avatar', this.avatar);
    }

    this.accountService
      .update(data)
      .pipe(
        catchError(err => {
          // @TODO handle API error
          console.error(err);
          return throwError(() => err);
        }),
        finalize(() => {
          this.pending = false;
        }),
      )
      .subscribe(result => {
        if (result) {
          this.isSent = true;
        }
      });
  }

  sendEmail() {
    this.accountService.resendVerificationEmail().subscribe(() => {
      this.toast.show(this.translateService.instant('SH.WELCOME_MODAL.EMAIL_RESENT'));
    });
  }

  correctInfo() {
    this.isSent = false;
  }

  avatarUpdate(url: string) {
    this.avatar = url;
  }
}
