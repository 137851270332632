import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { LogoDesktopComponent } from './logo-desktop/logo-desktop.component';
import { UserComponent } from './user/user.component';

@Component({
  selector: 'sh-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, UserComponent, LogoDesktopComponent],
})
export class TopBarComponent {
  showAccount = environment?.ui?.top?.account ?? false;
}
