import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMediaFile } from '@smarttypes/core';
import { map, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  static apiUrlPath = `https://smart-core-api.smarthotelapi.com/media-library`;

  constructor(private httpClient: HttpClient) {}

  public list(offset: number, folder?: string, search?: string, fileName?: string): Observable<IMediaFile[]> {
    let params = new HttpParams();
    params = params.set('offset', offset.toString());
    if (search) {
      params = params.set('search', search);
    } else {
      params = params.set('directoryPath', folder ? `/${folder}/` : '/');
    }
    if (fileName) {
      params = params.set('fileName', fileName);
    }
    return this.httpClient.get<IMediaFile[]>(`${MediaLibraryService.apiUrlPath}/list`, {
      params,
    });
  }

  public get(id: string): Observable<IMediaFile[]> {
    return this.httpClient.get<IMediaFile[]>(`${MediaLibraryService.apiUrlPath}/${id}`);
  }

  public folders(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${MediaLibraryService.apiUrlPath}/folders`);
  }

  public set(data: any): Observable<{ file: IMediaFile; signedUploadPath: string }> {
    return this.httpClient.post<{ file: IMediaFile; signedUploadPath: string }>(
      `${MediaLibraryService.apiUrlPath}`,
      data,
    );
  }

  public save(data: Partial<IMediaFile>, image: string): Observable<IMediaFile> {
    let file: IMediaFile;
    return this.httpClient
      .post<{ file: IMediaFile; signedUploadPath: string }>(`${MediaLibraryService.apiUrlPath}`, data)
      .pipe(
        switchMap(value => {
          file = value.file;
          return this.uploadFileToCloud(value.signedUploadPath, this.b64toBlob(image, file.type));
        }),
        map(() => file),
      );
  }

  public update(id: string, data: unknown): Observable<IMediaFile> {
    return this.httpClient.patch<IMediaFile>(`${MediaLibraryService.apiUrlPath}/${id}`, data);
  }

  public remove(id: string): Observable<IMediaFile> {
    return this.httpClient.delete<IMediaFile>(`${MediaLibraryService.apiUrlPath}/${id}`, {
      body: {
        id,
      },
    });
  }

  public uploadFileToCloud(url: string, file: any): Observable<any> {
    return this.httpClient.put(url, file);
  }

  public b64toBlob(b64Data: any, type = 'image/png'): Blob {
    let data;
    const aux = b64Data.split(',');
    if (aux.length > 1) {
      data = aux[1];
    } else {
      data = b64Data;
    }
    const byteString = atob(data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
