import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LoaderComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-button-tertiary',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, LoaderComponent],
  templateUrl: './button-tertiary.component.html',
  styleUrls: ['./button-tertiary.component.scss'],
})
export class ButtonTertiaryComponent {
  @Input() disabled = false;
  @Input() label = '';
  @HostBinding('class.pending') @Input() pending = false;
  @Input() size: 's' | 'm' | 'l' | 'xl' = 'm';

  get sizeClass() {
    return `button-size-${this.size}`;
  }
}
