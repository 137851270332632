import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IApartment, IReservation } from '@smarttypes/hotel';
import { ButtonPillComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationsService } from 'angular-v2-services';
import { format, isBefore, subWeeks } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { first, get, last } from 'lodash';

@Component({
  selector: 'ui-my-profile-reservations-item',
  templateUrl: './my-profile-reservations-item.component.html',
  styleUrls: ['./my-profile-reservations-item.component.scss'],
  imports: [TranslateModule, CommonModule, ButtonRectangleComponent, ButtonPillComponent, AngularSvgIconModule],
  standalone: true,
})
export class MyProfileReservationsItemComponent implements OnInit {
  @Input() public reservation?: IReservation;
  @Input() public isPast = false;
  @HostBinding('class.arrow') @Input() public arrow = false;
  @Input() public checkInButton = true;
  @HostBinding('class.small') @Input() small = false;
  @Output() public checkinClick: EventEmitter<string> = new EventEmitter<string>();
  public isOlderThan3Weeks = false;

  constructor(private reservationsService: ReservationsService) {}

  get isActive(): boolean {
    return this.reservation?.reservationId === localStorage.getItem('reservationId');
  }

  public get apartment(): IApartment {
    return this?.reservation?.room as IApartment;
  }

  public get image(): string {
    return get(
      this.apartment,
      'apartmentInfo.additionalInfo.linkToPhotoImage',
      'assets/images/room-placeholder.jpg',
    ) as string;
  }

  public get name(): string {
    return this.apartment?.roomNo || '';
  }

  public get adultCount(): number {
    return this.reservation?.adultCount || 0;
  }

  public get paid(): number {
    const payments = this.reservation?.payments;

    if ((payments?.length ?? 0) === 0) {
      return 0;
    }

    return last(payments)?.paid || 0;
  }

  public get currency(): string {
    const payments = this.reservation?.payments;

    if ((payments?.length ?? 0) === 0) {
      return '';
    }

    return last(payments)?.currency || '';
  }

  public get from(): string {
    return format(
      toDate(first((this.reservation?.from as unknown as string)?.split('T')) as string, {
        timeZone: 'Etc/UTC',
      }),
      `d MMM yyyy`,
    ).toString();
  }

  public get to(): string {
    return format(
      toDate(first((this.reservation?.to as unknown as string)?.split('T')) as string, {
        timeZone: 'Etc/UTC',
      }),
      `d MMM yyyy`,
    ).toString();
  }

  public get checkInSigned(): boolean {
    return !!this.reservation?.registrationCardId;
  }

  ngOnInit(): void {
    this.setIsOlderThan3Weeks();
  }

  private setIsOlderThan3Weeks() {
    const currentDate = new Date();
    const threeWeeksAgo = subWeeks(currentDate, 3);
    this.isOlderThan3Weeks = isBefore(new Date(this.reservation?.to as Date), threeWeeksAgo);
  }

  onCheckInClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.checkinClick.emit(this.reservation?.guestId as string);
  }
}
