import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ApiValidatorService {
  static MESSAGES = {
    required: 'FORM_VALIDATOR.REQUIRED',
    oneRequired: 'FORM_VALIDATOR.ONE_REQUIRED',
    min: 'FORM_VALIDATOR.MIN_NUMBER',
    max: 'FORM_VALIDATOR.MAX_NUMBER',
    maxlength: 'FORM_VALIDATOR.MAX_LENGTH_NUMBER',
    startsWith: 'FORM_VALIDATOR.MUST_STARTS_WITH',
    digits: 'FORM_VALIDATOR.DIGITS',
    validateEmail: 'FORM_VALIDATOR.EMAIL',
    email: 'FORM_VALIDATOR.EMAIL',
    validatePhone: 'FORM_VALIDATOR.PHONE',
    validateNotEqual: 'FORM_VALIDATOR.NOT_EQUAL',
    invalidDatesFromTo: 'FORM_VALIDATOR.INVALIDDATESFROMTO',
    mismatch: 'FORM_VALIDATOR.MISMATCH',
    tooHighSpecial: 'FORM_VALIDATOR.SPECIAL',
    periodError: 'FORM_VALIDATOR.PERIOD_ERROR',
    guestAllMsg: 'FORM_VALIDATOR.GUEST_ALL_MSG',
    specialOfferContent: 'FORM_VALIDATOR.SPECIAL_OFFER_CONTENT',
    noDefaultLang: 'FORM_VALIDATOR.NO_STORE_DEFAULT_LANG',
    roomIdsRange: 'FORM_VALIDATOR.ROOM_IDS_RANGE',
    amount: 'FORM_VALIDATOR.AMOUNT',
    onlyLettersAndNumbers: 'FORM_VALIDATOR.ONLY_LETTERS_AND_NUMBERS',
    url: 'FORM_VALIDATOR.URL',
    time: 'FORM_VALIDATOR.TIME',
    atLeastOneChannelRequired: 'FORM_VALIDATOR.AT_LEAST_ONE_CHANNEL',
    atLeastOneMessageRequired: 'FORM_VALIDATOR.AT_LEAST_ONE_MESSAGE',
    onlyUniqueEmails: 'FORM_VALIDATOR.ONLY_UNIQUE_EMAILS',
    onlyUniquePhones: 'FORM_VALIDATOR.ONLY_UNIQUE_PHONES',
  };
  public phoneRegex = /(^\+\d{1,2}[.\s]?)(\d{3}[.-]?){2}(\d{2}|\d{4})/;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.translateService.setDefaultLang(event.lang);
    });
  }

  static getValidationMessage(validator: string, validatorValue?: any, prefix = 'SH') {
    let message = get(ApiValidatorService, `MESSAGES.${validator}`, '');
    if (message) {
      message = `${prefix}.${message}`;
      if (validatorValue) {
        Object.keys(validatorValue).forEach(key => {
          message = message.replace(`{${key}}`, validatorValue[key]);
        });
      }
      return message;
    }
    return validatorValue;
  }

  validateEmail(c: FormControl): ValidationErrors | null {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    let email = c.value;
    if (!email) {
      return null;
    }
    email = email.email || email;
    return rgx.test(email)
      ? null
      : {
          validateEmail: {
            valid: false,
          },
        };
  }

  validateNotEqual(value: string) {
    return (fc: FormControl): ValidationErrors | null => {
      if (`${fc.value}`.toLowerCase() === `${value}`.toLowerCase()) {
        return {
          validateNotEqual: {
            valid: false,
          },
        };
      }
      return null;
    };
  }

  atLeastOneMessage(formGroup: FormGroup) {
    let empty = true;
    Object.keys(formGroup.controls).forEach(lang => {
      const channels = formGroup.controls[lang].value;
      Object.keys(channels).forEach(c => {
        const message = get(channels, c, null);
        if (message !== '' && message !== null) {
          empty = false;
        }
      });
    });

    if (empty) {
      return {
        required: true,
      };
    }
    return null;
  }

  atLeastOneChannelEnable(formGroup: FormGroup): ValidationErrors | null {
    const channels = Object.keys(formGroup.controls)
      .filter(key => ['phone', 'email', 'whatsapp', 'chatonline', 'sms'].includes(key.toLowerCase()))
      .filter(key => !!formGroup.controls[key].value);
    if (channels.length === 0) {
      return {
        atLeastOneChannelRequired: true,
      };
    }
    return null;
  }

  allOrNoneFieldsRequired(formGroup: FormGroup): ValidationErrors | null {
    const controls = formGroup.controls;
    const controlKeys = Object.keys(controls).filter(k => k !== 'opened');

    const filledFields = controlKeys.filter(key => {
      const value = controls[key].value;
      return value !== null && value !== '';
    });

    if (filledFields.length === 0) {
      return null;
    }

    if (filledFields.length < controlKeys.length) {
      controlKeys.forEach(key => {
        const value = controls[key].value;
        if (value === null || value === '') {
          controls[key].setErrors({ required: true });
        } else {
          controls[key].setErrors(null);
        }
      });
      return { allOrNoneFieldsRequired: true };
    }

    return null;
  }

  validateMultipleEmails(control: AbstractControl): ValidationErrors | null {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    let valid = true;
    (control?.value as []).map(e => {
      valid = rgx.test(e);
    });
    if (!valid) {
      return {
        validateEmail: {
          valid: false,
        },
      };
    }
    return null;
  }

  onlyLettersAndNumbers(control: AbstractControl) {
    const value = control.value;
    const regex = /^[A-Za-z0-9]*$/;

    if (!value) {
      return null;
    }

    return regex.test(value.toString())
      ? null
      : {
          onlyLettersAndNumbers: {
            valid: false,
          },
        };
  }

  validateUrl(control: AbstractControl) {
    const value = control.value;
    const regex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    if (!value) {
      return null;
    }

    return regex.test(value.toString())
      ? null
      : {
          url: {
            valid: false,
          },
        };
  }

  validateTime(control: AbstractControl) {
    const value = control.value;
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]/gm;
    if (!value) {
      return null;
    }

    return regex.test(value.toString())
      ? null
      : {
          time: {
            valid: false,
          },
        };
  }

  validatePhone = (c: FormControl): ValidationErrors | null => {
    let phone = c.value;
    if (!phone) {
      return null;
    }
    phone = phone.replace(/\s/g, '');
    return this.phoneRegex.test(phone)
      ? null
      : {
          validatePhone: {
            valid: false,
          },
        };
  };

  validateRoomIdsRange(fc: FormControl): ValidationErrors | null {
    const fromControl = fc?.get('roomIdFrom');
    const toControl = fc?.get('roomIdTo');
    if (fromControl?.pristine || toControl?.pristine) {
      return null;
    }
    return fromControl?.value <= toControl?.value ? null : { roomIdsRange: { valid: false } };
  }

  periodValidator(fc: FormControl) {
    const values = fc.value;
    return !values || (values && values.to && (values.from || values.from === 0))
      ? null
      : {
          periodError: {
            valid: false,
          },
        };
  }

  validateDatesRange(c: AbstractControl) {
    const startAt = c.get('from')?.value;
    const finishAt = c.get('to')?.value;

    if (finishAt !== null && startAt !== null) {
      return new Date(startAt).getTime() < new Date(finishAt).getTime()
        ? null
        : {
            invalidFinishAt: {
              valid: false,
            },
          };
    } else if (finishAt === null || startAt === null) {
      return {
        invalidDatesFromTo: {
          valid: false,
        },
      };
    }

    return null;
  }

  checkValid(form: FormGroup | FormArray) {
    for (const key of Object.keys(form.controls)) {
      if (!form?.get(key)?.valid) {
        form?.get(key)?.markAsDirty();
        if (form?.get(key) instanceof FormGroup || form?.get(key) instanceof FormArray) {
          console.log(key);
          this.checkValid(form?.get(key) as FormGroup);
        }
      }
    }
  }

  formIsValid(form: FormGroup | FormArray): boolean {
    if (!form.valid) {
      this.checkValid(form);
      form.markAsDirty();
    }
    return form.valid;
  }

  minLength(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value?.length < 4) {
        return {
          message: `${this.translateService.instant(
            'SH.FORM_VALIDATOR.MIN_LENGTH_NUMBER0',
          )} ${min} ${this.translateService.instant('SH.FormValidator.MinLengthNumber1')}`,
        };
      }
      return null;
    };
  }

  public rangeValidator(compareControlName: string, mustBeHigher = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        return null;
      }

      const controlValue = control?.value;
      const compareValue = control.parent.get(compareControlName)?.value;

      if (mustBeHigher) {
        if (controlValue < compareValue) {
          return null;
        }
      }

      if (!mustBeHigher) {
        if (controlValue > compareValue) {
          return null;
        }
      }

      return {
        message: mustBeHigher
          ? this.translateService.instant('SH.FORM_VALIDATOR.MAX_NUMBER')
          : this.translateService.instant('SH.FORM_VALIDATOR.MIN_NUMBER'),
      };
    };
  }

  public mustStartsWith(value: string | string[], allowEmpty = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      const messageValue = Array.isArray(value) ? value.join(` ${this.translateService.instant('or')} `) : value;
      const result = Array.isArray(value)
        ? value.some((text: string) => control.value?.startsWith(text))
        : control.value?.startsWith(value);

      if (result || (allowEmpty && control.value?.length === 0)) {
        return null;
      }

      return {
        message: `${this.translateService.instant('SH.FORM_VALIDATOR.MUST_STARTS_WITH')} ${messageValue}`,
      };
    };
  }

  // checkFormValidatorsFromApi(form: FormGroup | FormArray, errors: any) {
  //   for (const key of Object.keys(errors)) {
  //     if (form.controls[key]) {
  //       form.controls[key].setErrors({
  //         apiError: true,
  //       });
  //       form.controls[key].markAsTouched();
  //     }
  //   }
  // }
}
