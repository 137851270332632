import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CommunicationChannelEnum } from '@smarttypes/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export interface PercentageBar {
  color: string;
  percent: number;
  channel: CommunicationChannelEnum;
}

@Component({
  selector: 'ui-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageBarComponent {
  @Input() data?: PercentageBar[];

  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  public get isLoading(): boolean {
    return this._isLoading || false;
  }

  private _isLoading?: boolean;
  @ViewChildren('percentItem') public items?: QueryList<ElementRef>;

  get hasPercentages(): boolean {
    return this.data?.some(item => item.percent > 0) || false;
  }

  public onMouseEnter(channel: CommunicationChannelEnum): void {
    const items = this.items?.filter(item => item.nativeElement.getAttribute('data-channel') !== channel);

    items?.forEach(item => {
      item.nativeElement.classList.add('disabled');
    });
  }

  public onMouseLeave(): void {
    this.items?.forEach(item => {
      item.nativeElement.classList.remove('disabled');
    });
  }
}
