import { CommonModule } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@Component({
  selector: 'ui-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchButtonComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgxTippyModule, TranslateModule, CommonModule, FormsModule, AngularSvgIconModule, LoaderComponent],
})
export class SearchButtonComponent implements ControlValueAccessor {
  public searchActive = false;
  public value = '';
  public isRegister = false;
  @Input() public placeholder = this.translateService.instant('SH.FRONTEND_LIB.SEARCH_BUTTON.SEARCH_GUESTS') + '..';
  @Input() public pending = false;
  @Input() public name = '';
  @Input() public disabled = false;
  @ViewChild('input', { static: true }) public input!: ElementRef;
  private onTouch!: () => void;
  private onModelChange!: (value: any) => void;

  constructor(private translateService: TranslateService) {}

  registerOnChange(fn: any): void {
    this.isRegister = true;
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  inputBlur() {
    if (!this.value || this.value.trim() === '') {
      this.searchActive = false;
    }
  }

  setActive() {
    this.searchActive = true;

    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
      }
    }, 100);
  }

  changeValue(val: string) {
    this.value = val;
    if (this.isRegister) {
      this.onTouch();
      this.onModelChange(this.value);
    }
  }

  onClear() {
    this.changeValue('');
  }
}
