import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'wg-form-add-custom-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, ButtonCircleComponent, FormsModule],
  templateUrl: './form-add-custom-button.component.html',
  styleUrls: ['./form-add-custom-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAddCustomButtonComponent {
  @ViewChild('input', { static: false }) public input?: ElementRef;
  @Input() public maxTitleLength = 40;
  @Input() public pending = false;
  @Output() add: EventEmitter<string> = new EventEmitter<string>();
  public title?: string;

  constructor(private readonly _cd: ChangeDetectorRef) {}

  public get titleCounter(): number {
    return this.title?.length ?? 0;
  }

  public onButtonClick(): void {
    this.input?.nativeElement?.focus();
  }

  public onSave(): void {
    this.add.emit(this.title);
    this.title = '';
    this._cd.detectChanges();
  }
}
