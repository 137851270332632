import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@smarthotel/angular-ui';
import { AppModules, IAccount, ICompany, ICompanyRole } from '@smarttypes/core';
import { AvatarComponent } from '@ui/common';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ngIfDropdownOpenTrigger } from 'angular-v2-animations';
import { get } from 'lodash';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { distinctUntilKeyChanged, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AccountService } from '../../../../core/services/account.service';
import { AuthService } from '../../../../core/services/auth.service';
import { CompanyService } from '../../../../core/services/company.service';

@Component({
  selector: 'sh-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [ngIfDropdownOpenTrigger],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AvatarComponent,
    ButtonRectangleComponent,
    BsDropdownModule,
    RouterLinkWithHref,
    AngularSvgIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit, OnDestroy {
  user!: IAccount;
  company!: ICompanyRole;
  companies: ICompany[] = [];
  filteredCompanies: ICompany[] = [];
  appFunctionality = AppModules;
  private subscriptions: Subscription = new Subscription();

  get displayName(): string {
    if (this.user?.person?.firstName) {
      return this.user?.person?.firstName;
    }
    return '-- --';
  }

  get isServiceAccount(): boolean {
    return get(this.company, 'role', '') === 'service';
  }

  constructor(
    private cd: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly accountService: AccountService,
    private readonly companyService: CompanyService,
    protected modalService: BsModalService,
    protected router: Router,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.accountService.$currentUser().subscribe(user => {
        this.user = user;
        this.cd.detectChanges();
      }),
    );
    this.subscriptions.add(
      this.companyService
        .$currentUser()
        .pipe(
          filter(c => !!c),
          distinctUntilKeyChanged('_id'),
        )
        .subscribe(company => {
          this.company = company;
          this.cd.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLogout() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        text: {
          header: 'SH.USER.LOGOUT.HEADER',
          body: 'SH.USER.LOGOUT.TEXT',
          submit: 'SH.USER.LOGOUT.SUBMIT',
          btnClass: 'danger',
        },
        confirmFunc: () => {
          this.authService.logout().subscribe();
        },
      },
      class: 'modal-dialog-small',
    });
  }
}
