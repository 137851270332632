import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, FormComponent, SelectComponent } from '@ui/common/forms';
import { FormControlDirective } from 'angular-v2-directives';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wg-form-social-item',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SelectComponent,
    FormComponent,
    ErrorMessageComponent,
    FormControlDirective,
    ButtonCircleComponent,
  ],
  templateUrl: './form-social-item.component.html',
  styleUrls: ['./form-social-item.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSocialItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() socials: { value: string; label: string }[] = [];
  @Input() selectedSocials: string[] = [];
  @Input() fg: FormGroup = new FormGroup({});
  @Input() index?: number;
  @Output() remove: EventEmitter<number> = new EventEmitter<number>();
  private subscription: Subscription = new Subscription();
  public availableOptions: {
    value: string;
    label: string;
    disabled?: boolean;
  }[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  public get label(): string {
    return this.socials.find(s => s.value === this.fg?.get('type')?.value)?.label ?? '';
  }

  public get prefix(): string {
    const type = this.fg?.get('type')?.value ?? '';
    switch (type) {
      case 'www':
        return 'https://';
      default:
        return `https://${type.toLowerCase()}.com/`;
    }
  }

  public get value(): string {
    return this.fg?.get('value')?.value ?? '';
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedSocials']?.currentValue !== changes['selectedSocials']?.previousValue) {
      this.availableOptions = this.socials
        .map(item => {
          return {
            ...item,
            disabled: this.selectedSocials.filter(v => v !== this.prefix).includes(item.value),
          };
        })
        .filter(s => !s.disabled);
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.fg?.get('type')?.valueChanges?.subscribe(() => {
        this.fg?.get('value')?.reset();
        this.cd.detectChanges();
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
